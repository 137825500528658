import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../config";
import { Link } from "react-router-dom";
import { ApiCallState } from "../api/common";
import { apiGetOrgInfo, IOrganizationInfo } from "../api/odkey-api";
import { PMSLink } from "./PMSLink";

export function Navbar() {

  const { logout, getAccessTokenSilently } = useAuth0();

  const [orgInfo, setOrgInfo] = React.useState<ApiCallState<IOrganizationInfo>>({});

  // load org info

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })()
    .then((result) => setOrgInfo({ result }))
    .catch((err) => setOrgInfo({ error: `${err}` }))},
    [getAccessTokenSilently]);


  return (

    <nav className="navbar border-bottom border-secondary bg-white">
      <div className="container">
        <ul className="nav mx-auto">
          <li className="nav-item"><Link className="nav-link text-muted" to="/lock-groups/">Schließgruppen</Link></li>
          <li className="nav-item"><Link className="nav-link text-muted" to="/solve/">Aufgaben</Link></li>
          <li className="nav-item"><a className="nav-link text-muted" href={PMSLink(orgInfo.result?.gmType)} target={orgInfo.result?.gmType === 'odkey_pms' ? "_self" : "_blank"} rel="noreferrer">PMS</a></li>
          <li className="nav-item">
            <button
              type="button"
              className="btn btn-sm btn-secondary m-1 align-middle"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Logout"
              onClick={() => logout({ returnTo: `${window.location.origin}${config.basename}` })}>
              <i className="bi bi-door-closed-fill text-primary" />
            </button>
          </li>
        </ul>
      </div>
    </nav>
  )
}
