
export function buildQueryParameters(params: Object): string {
  const parts: string[] = [];
  for(const [key, value] of Object.entries(params)) {
    // ignore values considered false
    if (value === undefined || value === false)
      continue

    if (value === true) { // boolean true
      parts.push(key);
    } else if (typeof(value) == 'string') {
      parts.push(`${key}=${value}`);
    } else {
      throw new Error('cannot build query parameter')
    }
  }
  return parts.length ? `?${parts.join('&')}` : ''
}
