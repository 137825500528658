import styles from './ApiLoadingGuard.module.scss'
import { PropsWithChildren } from 'react'
import { ApiCallState } from '../api/common'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import toast from 'react-hot-toast';

export interface LoadingGuardProps<T = any> {
  apiStates: ApiCallState<T>[];
  style?: 'inline';
}

export function ApiLoadingGuard(props: PropsWithChildren<LoadingGuardProps>): JSX.Element | null {
  const hasAllResults = props.apiStates.every((v) => !!v && !!v.result)

  if (hasAllResults) {
    return props.children ? (<>{props.children}</>) : null
  } else {
    const hasError = !!props.apiStates.find((v) => !!v.error);
    // handle api errors
    if (hasError) {
      //console.error(props.apiStates.filter((v) => !!v.error));
      toast.error('Da ist etwas schief gelaufen. Bitte laden Sie die Seite erneut. Sollte dieser Fehler öfter auftreten, kontaktieren Sie bitte den für Sie zuständigen Ansprechpartner.');
    }

    if (props.style === 'inline') {
      return <LoadingSpinner />
    } else {
      return <div className={styles.loading} />
    }
  }
}
