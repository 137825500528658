
import React, { useState } from "react";
import { LockGroupStatusColor } from "../LockGroupsPage/LockGroupsRow";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiCallState } from "../api/common";
import { apiGetBookingConflicts, apiGetOrgBookings, apiGetOrgInfo, apiGetOrgLockGroups, IBooking, IBookingConflict, ILockGroup, IOrganizationInfo } from "../api/odkey-api";
import { PmsLogo } from "../LockGroupsPage/PmsLogo";
import { filterSearchTerm } from "../filter_search_term";
import { ApiLoadingGuard } from "../ApiLoadingGuard/ApiLoadingGuard";
import { Dropdown } from "../Dropdown/Dropdown";
import { useNavigate } from 'react-router-dom';
import pLimit from 'p-limit';

const limit = pLimit(16); // limit the number of concurrent requests to 16

interface BookingWithConflicts extends IBooking {
  conflicts: IBookingConflict[];
}

interface ConflictBookingDetailsRowProps {
  booking: BookingWithConflicts;
}

function ConflictBookingDetailsRow(props: ConflictBookingDetailsRowProps) {
  const navigate = useNavigate();
  const booking = props.booking;

  if (!booking.LockGroupId) {
    throw new Error('Lockgroup unset');
  }

  const bookingsUrl = `../lock-groups/${booking.LockGroupId}/bookings/${booking.id}`

  // evaluate conflicts to decide what to display
  const warning: string[] = [];
  const error: string[] = []
  for (const conflict of booking.conflicts) {
    switch (conflict.type) {
      case 'overlapping':
        warning.push('Buchungskonflikt');
        break;
      case 'update_failed':
        error.push('PMS Update fehlgeschlagen');
        break;
      case 'missing_data':
        error.push('Fehlende Daten');
        break;
      case 'no_access':
        warning.push('Keine Berechtigung vergeben');
        break;
      case 'key_delivery_failed':
        warning.push('Schlüsselübertragung fehlgeschlagen');
        break;
    }
  }
  return (
    <tr>
      <td role='button' onClick={() => navigate(bookingsUrl)}>
        <LockGroupStatusColor success={false} warning={warning.length ? warning.join(', ') : undefined}
          error={error.length ? error.join(', ') : undefined} />
      </td>
      <td role='button' onClick={() => navigate(bookingsUrl)}>{new Date(booking.arrivalAt).toLocaleString('DE-de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })}</td>
      <td role='button' onClick={() => navigate(bookingsUrl)}>{new Date(booking.departureAt).toLocaleString('DE-de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })}</td>
      <td role='button' onClick={() => navigate(bookingsUrl)}>{booking.guestName}</td>
      <td><button className="btn btn-sm btn-primary" onClick={() => navigate(bookingsUrl)}><i className="bi bi-three-dots"></i></button></td>
    </tr>
  )
}

interface MissingLockGroupBookingRowProps {
  booking: IBooking;
  lockGroups: ILockGroup[]
}

function MissingLockGroupBookingRow(props: MissingLockGroupBookingRowProps) {
  const booking = props.booking;
  return (
    <tr>
      <td ><LockGroupStatusColor success={false} warning={'Keine Schließgruppe'} error={undefined} /></td>
      <td>{new Date(booking.arrivalAt).toLocaleString('DE-de')}</td>
      <td>{new Date(booking.departureAt).toLocaleString('DE-de')}</td>
      <td>{booking.guestName}</td>
      {/* <td>
        <Dropdown header='Schließgruppe zuweisen'
          menu={props.lockGroups.map((v) => v.displayName)}
          selectionHandler={() => console.log('klick')}></Dropdown>
      </td>
      <td><button className="btn btn-sm btn-primary"><i className="bi bi-three-dots" /></button></td> {/*TODO: Give this dummy a function...*/}
    </tr>
  )
}


export function ConflictPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [orgInfo, setOrgInfo] = React.useState<ApiCallState<IOrganizationInfo>>({});
  const [danglingBookings, setDanglingBookings] = React.useState<ApiCallState<IBooking[]>>({});
  const [conflictBookings, setConflictBookings] = React.useState<ApiCallState<BookingWithConflicts[]>>({});
  const [lockGroups, setLockGroups] = React.useState<ApiCallState<ILockGroup[]>>({});
  const [searchTerm, setSearchTerm] = useState('')

  // load org info
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })().then((result) => setOrgInfo({ result })).catch((err) => setOrgInfo({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // load lock groups
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgLockGroups(token);
    })().then((result) => setLockGroups({ result })).catch((err) => setLockGroups({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // load bookings
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgBookings(token, undefined, { dangling: true, excludePast: true });
    })().then((result) => setDanglingBookings({ result })).catch((err) => setDanglingBookings({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // get conflicts for all bookings
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const bookings = await apiGetOrgBookings(token, undefined, { dangling: false, excludePast: true });
      const conflicts = await Promise.all(bookings.map(b =>
        limit(() => apiGetBookingConflicts(token, b.id).then(conflicts => ({ ...b, conflicts })))
      ));
      return conflicts.filter((v) => v.conflicts.length);
    })().then((result) => setConflictBookings({ result })).catch((err) => setConflictBookings({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  const filterBookings = filterSearchTerm<IBooking>('guestName', searchTerm)

  return (
    <ApiLoadingGuard apiStates={[orgInfo, danglingBookings, conflictBookings]}>
      <div className="container pt-5">
        <h1>Offene Aufgaben</h1>
        <p>Organisation: {orgInfo.result?.name}</p>
        <p>PMS: <PmsLogo pms={orgInfo.result?.gmType} /></p>
        <br />
        {danglingBookings.result?.length || conflictBookings.result?.length ?
          <div className="pt-3 pb-5">
            <input type="text" className="form-control" id="Searchbar" placeholder="Nach Buchung suchen..." onChange={event => { setSearchTerm(event.target.value) }} />
          </div>
          : null}
        <h2>Nicht zugewiesene Schließgruppen</h2>
        {danglingBookings.result?.length ?
          <div className="pt-5 pb-5">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">von</th>
                    <th scope="col">bis</th>
                    <th scope="col">Gast</th>
                    {/* <th scope="col">Schließgruppe</th>
                    <th scope="col"></th> */}
                  </tr>
                </thead>
                <tbody>
                  {filterBookings(danglingBookings.result).map((b, idx) => <MissingLockGroupBookingRow key={idx} booking={b} lockGroups={lockGroups.result || []} />)}
                </tbody>
              </table>
            </div>
            <i className="bi bi-key-fill text-warning"></i>&nbsp;Ausstehend&nbsp;&nbsp;<i className="bi bi-key-fill text-danger"></i>&nbsp;Fehler
            <br />
          </div>
          :
          <p className="pt-5 pb-5">Derzeit gibt es keine nicht zugewiesenen Schließgruppen.</p>}
        <h2>Fehler und sonstige Konflikte</h2>
        {conflictBookings.result?.length ?
          <div className="pt-5 pb-5">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "5%" }}>Status</th>
                    <th scope="col">von</th>
                    <th scope="col">bis</th>
                    <th scope="col">Gast</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filterBookings(conflictBookings.result).map((b, idx) => <ConflictBookingDetailsRow key={idx} booking={b as BookingWithConflicts} />)}
                </tbody>
              </table>
            </div>
            <i className="bi bi-key-fill text-warning"></i>&nbsp;Ausstehend&nbsp;&nbsp;<i className="bi bi-key-fill text-danger"></i>&nbsp;Fehler
            <br />
          </div>
          :
          <p className="pt-5 pb-5">Derzeit gibt es keine Fehler oder Konfikte.</p>}
      </div>
    </ApiLoadingGuard >
  );
}
