
import { Link } from "react-router-dom"; // If you are using React Router
import styles from './NoPage.module.scss';
import { TransparentFooter } from "../TransparentFooter/TransparentFooter";

export function NoPage() {
  return (
    <main className={styles.wrapper}> {/* Use semantic HTML tag */}
      <span className={styles.logo}>
        <Link to="/"><img src="/logo512.png" alt="odKEY Logo" className="img-fluid"/></Link>
      </span>
      <br />
      <h1>Fehler 404 - Die Seite wurde nicht gefunden.</h1>
      <br />
      <Link className="btn btn-lg btn-secondary m-1" to="/" role="button">Zurück</Link>
      <a className="btn btn-lg btn-primary m-1" href="mailto:info@odkey.de" role="button">Kontakt</a>
      <br />
      <br />
      <div className={styles.footer}>
        <TransparentFooter />
      </div>
    </main>
  );
}

