
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { NoPage } from './NoPage/NoPage';
import { withAuthenticationRequired, Auth0Provider } from "@auth0/auth0-react";
import { MainLayout } from './MainLayout/MainLayout';
import { BookingsPage } from './BookingsPage/BookingsPage';
import { LockGroupsPage } from './LockGroupsPage/LockGroupsPage'
import { BookingDetailsPage } from './BookingDetailsPage/BookingDetailsPage'
import { config } from './config';
import { UserInfo } from './UserInfo/UserInfo';
import { ConflictPage } from './ConflictPage/ConflictPage';
import { AddBookingManuallyPage } from './AddBookingManuallyPage/AddBookingManuallyPage';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate('/');
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};


export interface AppRouterProps {
  basename?: string
}

export function AppRouter({basename}: AppRouterProps) {

  const AuthedMainLayout = withAuthenticationRequired(MainLayout)
  return (
    <BrowserRouter basename={basename || ''}>
      <Auth0ProviderWithRedirectCallback
        domain={config.oauthClient.domain}
        clientId={config.oauthClient.clientId}
        redirectUri={`${window.location.origin}${config.basename}`}
        audience={config.oauthClient.audience}
      >
        <Routes>
          <Route path="/" element={<AuthedMainLayout />}>
            <Route index element={<Navigate to='lock-groups' />} />
            <Route path="lock-groups" element={<LockGroupsPage />} />
            <Route path="lock-groups/:lockGroupId/bookings" element={<BookingsPage />} />
            <Route path="lock-groups/:lockGroupId/bookings/add" element={<AddBookingManuallyPage />} />
            <Route path="lock-groups/:lockGroupId/bookings/:bookingId" element={<BookingDetailsPage />} />
            <Route path="user" element={<UserInfo />}></Route>
            <Route path="solve" element={<ConflictPage />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  )
}
