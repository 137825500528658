
import { useNavigate } from 'react-router-dom';
import { ILockGroup } from '../api/odkey-api';
import styles from './LockGroupsPage.module.scss';


// TODO: move to api
export interface LockGroup {
  lockVendor: string
  lockGroupName: string
  activeKeyCount: number
  lockgroupId: string
  warning?: string | undefined  // contains warning if set
  error?: string | undefined  //contains errro message if set
  success: boolean
}

const vendorLogos: { [key: string]: string } = {
  "Dormakaba": "/dormakaba96x96.png",
  "Wilka": "/wilka.ico",
  "Unknown": "/favicon.ico"
};


export function LockVendorLogo({ vendor }: { vendor: string }) {
  return (
    <img className={styles.icons} src={vendorLogos[vendor] || vendorLogos['Unknown']} title={vendor} alt="" />
  );
}

export function LockGroupStatusColor({ success, warning, error }: { success: boolean, warning?: string, error?: string }) {
  if (success) {
    return (<div className={`${styles.table_status} ${styles.success}`}><i className="bi bi-key-fill text-success p-3 align-middle"></i></div>)
  } else {
    if (success === false && warning !== undefined) {
      return (<div title={warning} className={`${styles.table_status} ${styles.warning}`}><i className="bi bi-key-fill text-warning p-3 align-middle"></i></div>)
    } else {
      return (<div title={error} className={`${styles.table_status} ${styles.error}`}><i className="bi bi-key-fill text-danger p-3 align-middle"></i></div>)
    }
  }
}

export function LockGroupsRow({ lockGroup }: { lockGroup: ILockGroup }) {

  const navigate = useNavigate()
  const bookingsUrl = `./${lockGroup.id}/bookings/`
  return (
    <tr>
      {/* TODO: <td><LockGroupStatusColor success={lockGroup.success} warning={lockGroup.warning} error={lockGroup.error} /></td> */}
      <td role='button' onClick={() => navigate(bookingsUrl)}><LockGroupStatusColor success={true}/></td>
      <td role='button' onClick={() => navigate(bookingsUrl)}>{lockGroup.displayName}</td>
      {/* TODO: <td>{lockGroup.activeKeyCount}</td> */}
      {/*<td>??</td>*/}{/* TODO: Uncomment - just for fair demo */}
      <td><button className="btn btn-sm btn-primary" title="Weitere Informationen" onClick={() => navigate(bookingsUrl)}><i className="bi bi-three-dots text-white"></i></button></td>
      {/* TODO: <td><LockVendorLogo vendor={lockGroup.vendor} /></td> */}
      {/* <td><LockVendorLogo vendor="Dormakaba" /></td> */}
      {/* TODO: Uncomment LockVendorLogo - just for fair demo */}
      <td></td>
    </tr>
  )
}
