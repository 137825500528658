

export async function runRequest<R=Object>(
    endpoint: string,
    method: string,
    body?: string,
    headers: {[key: string]: string} = {}): Promise<R> {
  console.log(`>> request "${endpoint}" body:`, body) // TODO: remove debug logging

  const resp = await fetch(endpoint, { method, body, headers })
  const respData = await resp.json()
  if (resp.status >= 200 && resp.status < 300) {
    console.log(`<< response "${endpoint}" body:`, respData) // TODO: remove debug logging
    return respData;
  } else {
    throw respData
  }
}


export async function postJsonData<S=Object, R=Object>(
    endpoint: string,
    data?: S,
    extraHeaders: {[key: string]: string} = {}): Promise<R> {
  const body = JSON.stringify(data)
  const headers = {
    ...extraHeaders,
    'Content-Type': 'application/json'
  }
  return await runRequest(endpoint, 'POST', body, headers)
}

export async function getJsonData<R=Object>(endpoint: string, extraHeaders: {[key: string]: string} = {}): Promise<R> {
  return await runRequest(endpoint, 'GET', undefined, extraHeaders)
}

export function authHeader(authToken: string): {'Authorization': string} {
  return { 'Authorization': `Bearer ${authToken}` }
}

export interface ApiCallState<T> {
  error?: string;
  result?: T;
}
