
import styles from './BookingsPage.module.scss';
import { IBooking } from "../api/odkey-api";


export function BookingStatusIcon({ booking }: { booking: IBooking }) {
  if (!booking.accessGrant) {
    // warning: incufficient data to start delivery
    return (<div className={`${styles.table_status} ${styles.warning}`}><i className="bi bi-key-fill text-warning p-3 align-middle"></i></div>)
  } else {
    if (booking.accessGrant.deliveryError) {
      // error
      return (<div className={`${styles.table_status} ${styles.error}`}><i className="bi bi-key-fill text-danger p-3 align-middle"></i></div>)
    } else {
      if (booking.accessGrant.deliveredAt) {
        // success
        return (<div className={`${styles.table_status} ${styles.success}`}><i className="bi bi-key-fill text-success p-3 align-middle"></i></div>)
      } else {
        // pending (sending in progress)
        return (<div className={`${styles.table_status} ${styles.pending}`}><i className="bi bi-key-fill text-dark p-3 align-middle"></i></div>)
      }
    }
  }
}