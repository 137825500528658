import { apiBookingSendKey, apiDeleteBooking, IBooking } from "../api/odkey-api";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from "../Modal/Modal";
import { BookingStatusIcon } from "./BookingStatusIcon";
import { useNavigate } from "react-router-dom";

export function BookingRow({ booking, openModal, closeModal, onBookingUpdate, inPast }: { booking: IBooking, openModal: Function, closeModal: Function, onBookingUpdate: Function, inPast: boolean }) {
  const { getAccessTokenSilently } = useAuth0();

  function resendHandler() {
    function doResend(): void {
      (async() => {
        const token = await getAccessTokenSilently();
        await apiBookingSendKey(token, booking.id);
        closeModal();
      })();
    }
    openModal(
      <Modal onCancel={() => closeModal()} onConfirm={() => doResend()}>
        <p>Wollen Sie die Berechtigung wirklich erneut senden?</p>
      </Modal>)
  }

  function deleteHandler() {
    function doDeleteBooking(): void {
      (async() => {
        const token = await getAccessTokenSilently();
        await apiDeleteBooking(token, booking.id);
        closeModal();
        onBookingUpdate();
      })();
    }
    openModal(
      <Modal onCancel={() => closeModal()} onConfirm={() => doDeleteBooking()}>
        <p>Wollen Sie die Berechtigung wirklich löschen?</p>
      </Modal>)
  }

  const navigate = useNavigate()
  const bookingDetailsLink = `./${booking.id}`

  if (inPast === false) {
    return (
      <tr>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}><BookingStatusIcon booking={booking} /></td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{new Date(booking.arrivalAt).toLocaleString('DE-de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}</td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{new Date(booking.departureAt).toLocaleString('DE-de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}</td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{booking.guestName}</td>
        {/* <td className="align-middle">??</td> */}{/* TODO: Uncomment - just for fair demo */}
        <td><button className="btn btn-sm btn-primary" title="Weitere Informationen" onClick={() => navigate(bookingDetailsLink)}><i className="bi bi-three-dots text-white"></i></button></td>
        <td><button className="btn btn-sm btn-warning" onClick={resendHandler} title="Schlüssel erneut senden"><i className="bi bi-send-plus"></i></button></td>
        <td><button className="btn btn-sm btn-danger" onClick={deleteHandler} title="Berechtigung löschen"><i className="bi bi-x-lg text-white"></i></button></td>
      </tr>
    )
  } else {
    return (
      <tr>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}><BookingStatusIcon booking={booking} /></td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{new Date(booking.arrivalAt).toLocaleString('DE-de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}</td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{new Date(booking.departureAt).toLocaleString('DE-de', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}</td>
        <td className="align-middle" role='button' onClick={() => navigate(bookingDetailsLink)}>{booking.guestName}</td>
        {/* <td className="align-middle">??</td> */}{/* TODO: Uncomment - just for fair demo */}
        <td><button className="btn btn-sm btn-primary" title="Weitere Informationen" onClick={() => navigate(bookingDetailsLink)}><i className="bi bi-three-dots text-white"></i></button></td>
        <td><button className="btn btn-sm btn-grey" disabled><i className="bi bi-send-plus"></i></button></td>
        <td><button className="btn btn-sm btn-grey" disabled><i className="bi bi-x-lg text-white"></i></button></td>
      </tr>
    )
  }
}