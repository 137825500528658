import { FC } from 'react'; // Import Functional Component type
import styles from './TransparentFooter.module.scss';

export const TransparentFooter: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      © Copyright {currentYear} odKEY
      <br />
      <a
        className={styles.link}
        href="https://odkey.de/impressum/"
        rel="noopener noreferrer"
        aria-label="Impressum"
      >
        Impressum
      </a>
      <span className={styles.separator} />
      <a
        className={styles.link}
        href="https://odkey.de/datenschutz/"
        rel="noopener noreferrer"
        aria-label="Datenschutz"
      >
        Datenschutz
      </a>
    </footer>
  )
}