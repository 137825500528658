
import styles from './LockGroupsPage.module.scss';


export function PmsLogo({ pms }: { pms: string | undefined}) {
  switch(pms) {
    case 'protel':
      // NOTE: we currently want to show protel pms as hotelbird
      return (<span>Hotelbird <img className={styles.pmsIcon} src="/hotelbird.png" title="Hotelbird" alt="" /></span>)
      // return (<span>Protel <img className={styles.pmsIcon} src="/protel.png" title="Protel" alt="" /></span>)
    case 'hotelbird':
      return (<span>Hotelbird <img className={styles.pmsIcon} src="/hotelbird.png" title="Hotelbird" alt="" /></span>)
    case 'odkey_pms':
      return (<span>odKEY-PMS</span>)
    default:
      return (<span>Unbekannt</span>)
  }
}
