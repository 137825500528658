
import React, { ReactNode, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { apiAddBooking } from "../api/odkey-api";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "../Modal/Modal";
import { Backdrop } from "../Backdrop/Backdrop";
import { useAuth0 } from "@auth0/auth0-react";



export function AddBookingManually() {
  const { lockGroupId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  // Modal
  const [modal, setModal] = useState<ReactNode | undefined>(undefined);
  // Add Booking form fields
  const [formArrival, setFormArrival] = useState<Date>(new Date());
  const [formDeparture, setFormDeparture] = useState<Date>(new Date());
  const [formGuestName, setFormGuestName] = useState('');
  const [formGuestMail, setFormGuestMail] = useState('');
  const navigate = useNavigate();

  function isValidDate() {
    return (formArrival < formDeparture) && (formArrival.toISOString() !== formDeparture.toISOString());
  }

  function formSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!isValidDate()) {
      alert('Das Ankunftsdatum muss vor dem Abreisedatum liegen!');
      return;
    }

    async function handleAddBooking(): Promise<void> {
      if (!lockGroupId) return;

      const booking = {
        guestName: formGuestName,
        guestEmail: formGuestMail,
        arrivalAt: formArrival,
        departureAt: formDeparture,
      };
      // TODO: handle errors and loading state
      const token = await getAccessTokenSilently();
      await apiAddBooking(token, lockGroupId, booking);
      setModal(undefined);
      navigate(`/lock-groups/${lockGroupId}/bookings/`);
    };


    setModal(
      <Modal onCancel={() => setModal(undefined)} onConfirm={() => handleAddBooking()}>
        Wollen Sie die Buchung für {formGuestName} wirklich hinzufügen?
      </Modal>)
  }

  return (
    <div className="pb-5">
      <h2>Buchung hinzufügen</h2>
      {modal}{modal && <Backdrop />}
      <form onSubmit={formSubmitHandler}>
        <div className="form-group">
          <label htmlFor="guestName">Name des Gastes</label>
          <input type="text" className="form-control" id="guestName" name="guestName" value={formGuestName} onChange={(e) => setFormGuestName(e.target.value)} placeholder="Max Mustermann" required />
        </div>
        <div className="form-group">
          <label htmlFor="guestEmail">Email-Adresse des Gastes</label>
          <input type="email" className="form-control" id="guestEmail" name="guestEmail" value={formGuestMail} onChange={(e) => setFormGuestMail(e.target.value)} placeholder="max@mustermann.de" required />
        </div>
        <div className="row pt-3">
          <div className="col">
            <label>Ankunft</label>
            <div>
              <DateTimePicker disableClock onChange={setFormArrival} value={formArrival} />
            </div>
          </div>

          <div className="col">
            <label>Abreise</label>
            <div>
              <DateTimePicker disableClock onChange={setFormDeparture} value={formDeparture} />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          <i className="bi bi-calendar-plus" />&nbsp;Buchen</button>
      </form>
      <button className="btn btn-danger mt-2" onClick={() => navigate(-1)}>
        <i className="bi bi-x-lg" />&nbsp;Abbrechen</button>
    </div>
  )
}
