
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react"
import { IUserInfo, apiGetUserInfo } from "../api/odkey-api";


export interface UserInfoState {
  info?: IUserInfo;
  error?: string;
}

export function UserInfo() {
  const [state, setState] = React.useState<UserInfoState>({});
  const { getAccessTokenSilently } = useAuth0();

  // get user info from api
  useEffect(() => {
    (async() => {
      const token = await getAccessTokenSilently();
      return await apiGetUserInfo(token);
    })().then((info) => setState({info})).catch((err) => setState({error: `${err}`}))
  }, [getAccessTokenSilently]);

  let res;
  if (state.info) {
    const info = state.info;
    res = (
      <ul>
        <li>id: {info.id}</li>
        <li>authId: {info.authId}</li>
        <li>displayName: {info.displayName}</li>
        <li>email: {info.email}</li>
      </ul>
    )
  }

  return (<div>{res}</div>)
}
