
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, ReactNode } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiCallState } from "../api/common";
import { apiGetBookingInfo, apiGetOrgInfo, IBookingInfo, IOrganizationInfo, lockConnectionTypeToVendorName, IBookingConflict, apiGetBookingConflicts, apiBookingSendKey, apiDeleteBooking } from "../api/odkey-api";
import { PmsLogo } from "../LockGroupsPage/PmsLogo";
import { ApiLoadingGuard } from "../ApiLoadingGuard/ApiLoadingGuard";
import { Modal } from "../Modal/Modal";
import { Backdrop } from "../Backdrop/Backdrop";


const dateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
};


export function BookingDetailsPage() {
  const { bookingId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [orgInfo, setOrgInfo] = useState<ApiCallState<IOrganizationInfo>>({});
  const [booking, setBooking] = useState<ApiCallState<IBookingInfo>>({});
  const [conflicts, setConflicts] = useState<ApiCallState<IBookingConflict[]>>({});
  const [modal, setModal] = useState<ReactNode | undefined>(undefined);
  const navigate = useNavigate();

  // load org info
  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })().then((result) => setOrgInfo({ result })).catch((err) => setOrgInfo({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // load booking info
  useEffect(() => {
    if (bookingId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetBookingInfo(token, bookingId);
      })().then((result) => setBooking({ result })).catch((err) => setBooking({ error: `${err}` }))
    }
  }, [bookingId, getAccessTokenSilently]);

  // load conflicts
  useEffect(() => {
    if (bookingId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetBookingConflicts(token, bookingId);
      })().then((result) => setConflicts({ result })).catch((err) => setConflicts({ error: `${err}` }))
    }
  }, [bookingId, getAccessTokenSilently]);

  function resendHandler() {
    function doResend(): void {
      (async () => {
        const token = await getAccessTokenSilently();
        await apiBookingSendKey(token, bookingId as string);
        setModal(undefined);
      })();
    }
    setModal(
      <Modal onCancel={() => setModal(undefined)} onConfirm={() => doResend()}>
        <p>Wollen Sie die Berechtigung wirklich erneut senden?</p>
      </Modal>)
  }

  function deleteHandler() {
    function doDeletBooking(): void {
      (async () => {
        const token = await getAccessTokenSilently();
        await apiDeleteBooking(token, bookingId as string);
        setModal(undefined);
        navigate(-1);
      })();
    }
    setModal(
      <Modal onCancel={() => setModal(undefined)} onConfirm={() => doDeletBooking()}>
        <p>Wollen Sie die Berechtigung wirklich löschen?</p>
      </Modal>)
  }

  // evaluate conflicts to decide what to display
  const warning: string[] = [];
  const error: string[] = []

  if (conflicts.result) {
    for (const conflict of conflicts.result) {
      switch (conflict.type) {
        case 'overlapping':
          warning.push('Warnung: Buchungskonflikt. Im selben Zeitraum ist diese Schließgruppe bereits mit einer anderen Berechtigung versehen.');
          break;
        case 'update_failed':
          error.push('Fehler: PMS Update fehlgeschlagen');
          break;
        case 'missing_data':
          error.push('Fehler: Fehlende Daten');
          break;
        case 'no_access':
          warning.push('Warnung: Keine Berechtigung vergeben. Ist eine Kontaktmöglichkeit (z.B. E-Mail) hinterlegt?');
          break;
        case 'key_delivery_failed':
          warning.push('Warnung: Schlüsselübertragung fehlgeschlagen');
          break;
      }
    }
    console.log('>>> Error: ', error)
  }

  //checks if the booking is in the future
  const isFutureDate = new Date(booking.result?.departureAt || '').getTime() > Date.now();

  return (
    <>
      {modal}{modal && <Backdrop />}
      <ApiLoadingGuard apiStates={[orgInfo, booking, conflicts]}>
        <div className="container pt-5">
          <h1>Buchungsdetails</h1>
          {/* {conflicts.result?.length ? <div className="alert alert-danger" role="alert"> {JSON.stringify(conflicts.result)} </div> : null} */}
          {warning.length ? <div className="alert alert-warning" role="alert"> {warning.map((warning, index) => <li key={index}>{warning}</li>)}</div> : null}
          {error.length ? <div className="alert alert-danger" role="alert"> {error}  </div> : null}
          <p><b>PMS:</b> <PmsLogo pms={orgInfo.result?.gmType} /></p>
          <p><b>Schließsystem:</b> {lockConnectionTypeToVendorName(booking.result?.lockGroup.lockConnection.type)}</p>
          <p><b>Schließgruppe:</b> {booking.result?.lockGroup.displayName}</p>
          {/* <p><b>Status:</b> ??</p> */}{/* TODO: Uncomment - just for fair demo */}{/* <p><b>Berechtigung gesendet am:</b> ??</p> */}{/* TODO: Uncomment - just for fair demo */}
          {/* <p><b>Buchungszeitraum:</b> {booking.result?.arrivalAt} <b>bis</b> {booking.result?.departureAt}</p> */}
          <p><b>Buchungszeitraum:</b> {booking.result?.arrivalAt.toLocaleString('DE-de', dateFormat)} <b>bis</b> {booking.result?.departureAt.toLocaleString('DE-de', dateFormat)}</p>
          <p><b>Name:</b> {booking.result?.guestName}</p>
          <p><b>Mailadresse:</b> {booking.result?.guestEmail}</p>
          {/* <p><b>Telefon:</b> ??</p> */}{/* TODO: Uncomment Phone - just for fair demo */}
          {/* <p><b>Berechtigung gesendet am:</b> ??</p> */}{/* TODO: Uncomment - just for fair demo */}
          {isFutureDate ?
            <div>
              <button className="btn btn-warning" onClick={() => resendHandler()}>Zugang erneut senden</button><br />
              <button className="btn btn-danger mt-1" onClick={() => deleteHandler()}>Zutrittsberechtigung löschen</button>
            </div>
            :
            <button className="btn btn-danger mt-3" onClick={() => navigate(-1)}>Zurück</button>}
          {/* <div className="border border primary p-3 mt-5">
                <p className="fs-4">Log:</p>
                {state.log.map(log => (
                <li>{log}</li>
              ))}
              </div> */}
        </div>
      </ApiLoadingGuard>
    </>
  );
}