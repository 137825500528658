
import { Outlet } from "react-router-dom";
import { Navbar } from "../Navbar/Navbar"
import styles from './MainLayout.module.scss';
import { TransparentFooter } from "../TransparentFooter/TransparentFooter";
import { Toaster } from 'react-hot-toast';

export function MainLayout() {
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <Toaster/>
        <Outlet />
      </div>
      <div className={styles.footer}>
        <TransparentFooter />
      </div>
    </div>
  );
}