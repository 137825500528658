import { IBooking } from "../api/odkey-api";
import { useState, ReactNode } from "react";
import { Backdrop } from "../Backdrop/Backdrop";
import { BookingRow } from "./BookingRow";

export function BookingTable({ bookings, inPast, onBookingUpdate }: { bookings?: IBooking[], inPast: boolean, onBookingUpdate: Function }) {
  const [modal, setModal] = useState<ReactNode | undefined>(undefined);
  const [sortAscending, setSortAscending] = useState(true);

  const columnWidthForBtns = "5%";

  if (!bookings) {
    // TODO: handle loading/error states
    return null;
  }

  function switchSortOrder() {
    setSortAscending(!sortAscending)
  }

  function sortBookings(a: IBooking, b: IBooking): number {
    return (new Date(b.arrivalAt).getTime() - new Date(a.arrivalAt).getTime()) * (sortAscending ? 1 : -1);
  }

  return (
    <div className="table-responsive">
      {modal}{modal && <Backdrop />}
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" style={{ width: columnWidthForBtns }}>Status</th>
            <th scope="col">von <i role='button' className="bi bi-arrow-down-up" onClick={() => switchSortOrder()} /></th>
            <th scope="col">bis</th>
            <th scope="col">Gast</th>
            {/* <th scope="col">Ausgabe</th> */}{/* TODO: Uncomment - just for fair demo */}
            <th scope="col" style={{ width: columnWidthForBtns }}></th>
            <th scope="col" style={{ width: columnWidthForBtns }}></th>
            <th scope="col" style={{ width: columnWidthForBtns }}></th>
          </tr>
        </thead>
        <tbody>
          {bookings.sort(sortBookings).map((b, idx) =>
            <BookingRow
              key={b.id || idx}
              booking={b}
              openModal={setModal}
              closeModal={() => setModal(undefined)}
              onBookingUpdate={onBookingUpdate}
              inPast={inPast}
            />
          )}
        </tbody>
      </table>
    </div>
  );
}