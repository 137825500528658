
import { authHeader, getJsonData, postJsonData, runRequest } from "./common";
import { buildQueryParameters } from "./utils";


/**
 * get user info
 */
export interface IUserInfo {
  id: string;
  authId: string;
  displayName: string;
  email: string;
}

export async function apiGetUserInfo(authToken: string): Promise<IUserInfo> {
  return await getJsonData('/api/user/', authHeader(authToken))
}

/**
 * get organization info
 */


export interface IDealerInfo {
  id: string;
  name: string;
  phone: string;
  email: string;
  website?: string;
}

export interface IOrganizationInfo {
  id: string;
  name: string;
  gmType: 'hotelbird'|'odkey_pms'|'apaleo'|'guestline'|'mews'|'sihot'|'hs3'|'protel'|'protel_air'|'oracle_suit8'|'oracle_v5'|'oracle_cloud'|'infor_hms'|'infor_starlight';
  dealer: IDealerInfo;
}


export async function apiGetOrgInfo(authToken: string, orgId?: string): Promise<IOrganizationInfo> {
  return await getJsonData(`/api/org/${orgId || '0'}`, authHeader(authToken));
}

/**
 *  get org lock groups
 */
export interface ILockGroup {
  id: string;
  displayName: string;
  info?: {
    owner?: string;
    address?: Partial<{
      country: string;
      city: string;
      zip: string;
      street: string;
      number: string;
    }>
  }
}

export interface ILockConnection {
  id: string;
  type: string;
}

export function lockConnectionTypeToVendorName(type?: string): string {
  // return user readable vendor for ILockConnection.type
  return {
    dormakaba: 'Dormakaba',
    evva: 'EVVA'
  }[type || ''] || 'unknown'
}

export interface ILockGroupInfo extends ILockGroup {
  lockConnection: ILockConnection;
}

export async function apiGetOrgLockGroups(authToken: string, orgId?: string): Promise<ILockGroup[]> {
  return await getJsonData(`/api/org/${orgId || '0'}/lockgroups`, authHeader(authToken));
}

export async function apiGetLockGroupInfo(authToken: string, lockGroupId: string): Promise<ILockGroupInfo> {
  return await getJsonData(`/api/lockgroup/${lockGroupId}`, authHeader(authToken));
}


/**
 * get org bookings
 */

export interface IAccessGrant {
  id: number;
  // deliveredAt unset mean that delivery was not successfull yet
  deliveredAt?: Date;
  // delivery is only successfull if this is unset
  deliveryError?: string
}

export interface IBookingSpec {
  guestName: string;
  guestEmail: string;
  arrivalAt: Date;
  departureAt: Date;
}

export interface IBooking extends IBookingSpec {
  id: string;
  // accessGrant not set means that delivery could not be started because of missing data
  accessGrant?: IAccessGrant;
  LockGroupId?: string;
}

export interface IBookingInfo extends IBooking {
  lockGroup: ILockGroupInfo
}

export async function apiGetOrgBookings(
      authToken: string,
      orgId?: string,
      options: {dangling?: boolean, excludePast?: boolean} = {}
    ): Promise<IBooking[]> {
  return await getJsonData(`/api/org/${orgId || '0'}/bookings${buildQueryParameters(options)}`, authHeader(authToken));
}

export async function apiGetBookingInfo(authToken: string, bookingId: string): Promise<IBookingInfo> {
  const booking = await getJsonData(`/api/booking/${bookingId}`, authHeader(authToken));
  return {
    ...booking,
    arrivalAt: new Date((booking as any)['arrivalAt']),
    departureAt: new Date((booking as any)['departureAt'])
  } as IBookingInfo
}

export async function apiDeleteBooking(authToken: string, bookingId: string): Promise<IBookingInfo> {
  return await runRequest(`/api/booking/${bookingId}`, 'DELETE', undefined, authHeader(authToken));
}

export async function apiBookingSendKey(authToken: string, bookingId: string): Promise<void> {
  return await postJsonData(`/api/booking/${bookingId}/sendkey`, undefined, authHeader(authToken));
}

export async function apiGetLockGroupBookings(authToken: string, lockGroupId: string, past: boolean = false): Promise<IBooking[]> {
  // TODO: refactor to use "options: {...}" parameter style for "past" and buildQueryParameters for generating query string
  return await getJsonData(`/api/lockgroup/${lockGroupId}/bookings${past ? '?past' : ''}`, authHeader(authToken));
}

export async function apiAddBooking(authToken: string, lockGroupId: string, booking: IBookingSpec): Promise<IBooking> {
  return await postJsonData(`/api/lockgroup/${lockGroupId}/add_booking`, booking, authHeader(authToken));
}

export interface IBookingConflict {
  type: 'overlapping'|'update_failed'|'missing_data'|'no_access'|'key_delivery_failed';
  message?: string;
  deadline?: Date;
}

export async function apiGetBookingConflicts(authToken: string, bookingId: string): Promise<IBookingConflict[]> {
  return await getJsonData(`/api/booking/${bookingId}/conflicts`, authHeader(authToken));
}
