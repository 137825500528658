
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallState } from "../api/common";
import { apiGetLockGroupBookings, apiGetLockGroupInfo, apiGetOrgInfo, IBooking, ILockGroupInfo, IOrganizationInfo, lockConnectionTypeToVendorName } from "../api/odkey-api";
import { filterSearchTerm } from "../filter_search_term";
import { ApiLoadingGuard } from "../ApiLoadingGuard/ApiLoadingGuard";
import { BookingTable } from "./BookingTable";

export function BookingsPage() {
  const { lockGroupId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [orgInfo, setOrgInfo] = useState<ApiCallState<IOrganizationInfo>>({});
  const [lockGroup, setLockGroup] = useState<ApiCallState<ILockGroupInfo>>({});
  const [bookings, setBookings] = useState<ApiCallState<IBooking[]>>({});
  const [bookingUpdateTrigger, setBookingUpdateTrigger] = useState<number>(0);
  const [pastBookings, setPastBookings] = useState<ApiCallState<IBooking[]>>({});
  const navigate = useNavigate()

  //Searchbar
  const [searchTerm, setSearchTerm] = useState('')
  const filterBookings = filterSearchTerm<IBooking>('guestName', searchTerm)
  const filteredBookings = bookings.result ? filterBookings(bookings.result) : undefined;
  const filteredPastBookings = pastBookings.result ? filterBookings(pastBookings.result) : undefined;

  // load org info
  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })().then((result) => setOrgInfo({ result })).catch((err) => setOrgInfo({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // load lockGroup
  useEffect(() => {
    if (lockGroupId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetLockGroupInfo(token, lockGroupId);
      })().then((result) => setLockGroup({ result })).catch((err) => setLockGroup({ error: `${err}` }))
    }
  }, [lockGroupId, getAccessTokenSilently]);

  // load bookings
  useEffect(() => {
    if (lockGroupId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetLockGroupBookings(token, lockGroupId);
      })().then((result) => setBookings({ result })).catch((err) => setBookings({ error: `${err}` }))
    }
  }, [lockGroupId, bookingUpdateTrigger, getAccessTokenSilently]);

  // load past bookings
  useEffect(() => {
    if (lockGroupId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetLockGroupBookings(token, lockGroupId, true);
      })().then((result) => setPastBookings({ result })).catch((err) => setPastBookings({ error: `${err}` }))
    }
  }, [lockGroupId, getAccessTokenSilently]);

  return (
    <ApiLoadingGuard apiStates={[orgInfo, lockGroup, bookings]}>
      <div className="container pt-5">
        <div>
          <h1>{lockGroup.result?.displayName}</h1>
          {/* TODO: display lock vendor logo */}
          <p><b>Schließsystem:&nbsp;</b> {lockConnectionTypeToVendorName(lockGroup.result?.lockConnection.type)}</p>
          {lockGroup.result?.info?.owner ? <p><b>Objektinhaber: </b>{lockGroup.result?.info?.owner}</p> : ''}
          {lockGroup.result?.info?.address ? <p>
            <b>Objektanschrift:&nbsp;</b>
            {lockGroup.result?.info?.address?.street ? lockGroup.result?.info?.address?.street + ' ' : ''}
            {lockGroup.result?.info?.address?.number ? lockGroup.result?.info?.address?.number + ' ' : ''}
            {lockGroup.result?.info?.address?.zip ? lockGroup.result?.info?.address?.zip + ' ' : ''}
            {lockGroup.result?.info?.address?.city ? lockGroup.result?.info?.address?.city + ' ' : ''}
            {lockGroup.result?.info?.address?.country ? lockGroup.result?.info?.address?.country + ' ' : ''}
          </p> : null}

          <p><b>Ansprechpartner:&nbsp;</b>
            <a href={orgInfo.result?.dealer.website} target='_blank' rel="noreferrer">{orgInfo.result?.dealer.name}</a>&nbsp;|&nbsp;
            <a href={`tel:${orgInfo.result?.dealer.phone}`}>{orgInfo.result?.dealer.phone}</a>&nbsp;|&nbsp;
            <a href={`mailto:${orgInfo.result?.dealer.email}?subject=Frage zu odKEY Objekt: ${lockGroup.result?.displayName}`}>{orgInfo.result?.dealer.email}</a>
          </p>
        </div>
        <div>
          <h2>Buchungen</h2>
          <button onClick={() => navigate(`./add`)} className='btn btn-sm btn-secondary'><i className="bi bi-plus text-white"></i>Buchung hinzufügen</button>
          {bookings.result?.length || pastBookings.result?.length ?
            <div>
              <div className="pb-5 pt-1">
                <div className="pb-5 pt-3">
                  <input type="text" className="form-control" id="Searchbar" placeholder="Nach Buchung suchen..." onChange={event => { setSearchTerm(event.target.value) }} />
                </div>
                {bookings.result?.length ?
                  <div>
                    <h3>Aktive Buchungen <span className="badge bg-secondary">{filteredBookings?.length}</span></h3>
                    <BookingTable bookings={filteredBookings} onBookingUpdate={() => setBookingUpdateTrigger(bookingUpdateTrigger + 1)} inPast={false} />
                    <i className="bi bi-key-fill text-success"></i>&nbsp;Schlüsselübergabe erfolgreich&nbsp;&nbsp;<i className="bi bi-key-fill text-warning"></i>&nbsp;Ausstehend&nbsp;&nbsp;<i className="bi bi-key-fill text-danger"></i> Fehler
                    <br />
                  </div>
                  :
                  <p className="pt-5 pb-5">Derzeit keine Buchungen.</p>}
              </div>
              {pastBookings.result?.length ?
                <div className="pb-5">
                  <h3>Vergangene Buchungen <span className="badge bg-secondary">{filteredPastBookings?.length}</span></h3>
                  <BookingTable bookings={filteredPastBookings} onBookingUpdate={() => setBookingUpdateTrigger(bookingUpdateTrigger + 1)} inPast={true} />
                  <i className="bi bi-key-fill text-success"></i>&nbsp;Schlüsselübergabe erfolgreich&nbsp;&nbsp;<i className="bi bi-key-fill text-warning"></i>&nbsp;Ausstehend&nbsp;&nbsp;<i className="bi bi-key-fill text-danger"></i> Fehler
                </div>
                :
                <p className="pt-5 pb-5">Keine vergangenen Buchungen.</p>}
            </div>
            :
            <p className="pt-5 pb-5">Es liegen keine Buchungen vor.</p>
          }
        </div>
      </div>
    </ApiLoadingGuard>
  );
}