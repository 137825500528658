
import React from "react";
import { useParams } from "react-router-dom";
import { ApiCallState } from "../api/common";
import { ILockGroupInfo, IOrganizationInfo, apiGetOrgInfo, apiGetLockGroupInfo } from "../api/odkey-api";
import { AddBookingManually } from "../AddBookingManually/AddBookingManually";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiLoadingGuard } from '../ApiLoadingGuard/ApiLoadingGuard';

export function AddBookingManuallyPage() {
  const { lockGroupId } = useParams();
  const [orgInfo, setOrgInfo] = React.useState<ApiCallState<IOrganizationInfo>>({});
  const [lockGroup, setLockGroup] = React.useState<ApiCallState<ILockGroupInfo>>({});
  const { getAccessTokenSilently } = useAuth0();

  // load org info
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })().then((result) => setOrgInfo({ result })).catch((err) => setOrgInfo({ error: `${err}` }))
  }, [getAccessTokenSilently]);
  // load lockGroup
  React.useEffect(() => {
    if (lockGroupId) {
      (async () => {
        const token = await getAccessTokenSilently();
        return await apiGetLockGroupInfo(token, lockGroupId);
      })().then((result) => setLockGroup({ result })).catch((err) => setLockGroup({ error: `${err}` }))
    }
  }, [getAccessTokenSilently, lockGroupId]);

const subject = `Frage zu odKEY Objekt: ${lockGroup.result?.displayName}`;
const encodedSubject = encodeURIComponent(subject);

  return (
    <ApiLoadingGuard apiStates={[orgInfo, lockGroup]}>
    <div className="container pt-5">
      <div>
        <h1>{lockGroup.result?.displayName}</h1>
        {/* <p><b>Schließsystem:</b> {lockConnectionTypeToVendorName(lockGroup.result.lockConnection.type)}</p> */}{/* TODO: Uncomment LockVendor - just for fair demo */}
        <p><b>Ansprechpartner: </b>
          <a href={orgInfo.result?.dealer.website} target='_blank' rel="noreferrer">{orgInfo.result?.dealer.name}</a>&nbsp;|&nbsp;
          <a href={`tel:${orgInfo.result?.dealer.phone}`}>{orgInfo.result?.dealer.phone}</a>&nbsp;|&nbsp;
          <a href={`mailto:${orgInfo.result?.dealer.email}?subject=${encodedSubject}`}>{orgInfo.result?.dealer.email}</a>
        </p>
      </div>
      <div className='pt-3'>
      <AddBookingManually />
      </div>
    </div>
    </ApiLoadingGuard>
  )
}
