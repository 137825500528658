export function PMSLink(pms: string | undefined) {

  switch (pms) {
    case 'hotelbird':
      return ("https://my.hotelbird.de/login")
    case 'odkey_pms':
      return ("/pms")
    case 'apaleo':
      return ("https://identity.apaleo.com/account/login")
    case 'guestline':
      return ("https://pms.eu.guestline.net")
    case 'mews':
      return ("https://app.mews.com/Commander/Home/SignIn")
    case 'sihot':
      return ("https://sihot.com")
    case 'hs3':
      return ("https://www.hs3-hotelsoftware.de")
    case 'protel':
      return ("https://www.protel.net/")
    case 'protel_air':
      return ("https://app.protel.net/login")
    case 'oracle_suit8':
      return ("https://www.oracle.com/de/industries/hospitality/products/suite8-property.html")
    case 'oracle_v5':
      return ("https://www.oracle.com/de/customers/v5-systems/")
    case 'oracle_cloud':
      return ("https://www.oracle.com/de/cloud/sign-in.html")
    case 'infor_hms':
      return ("https://www.infor.com/de-de/products/hms")
    case 'infor_starlight':
      return ("#")
    default:
      return "#"
  }
}