
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LockGroupsRow } from "./LockGroupsRow";
import { PmsLogo } from "./PmsLogo";
import { apiGetOrgInfo, apiGetOrgLockGroups, ILockGroup, IOrganizationInfo } from "../api/odkey-api";
import { ApiCallState } from "../api/common";
import { filterSearchTerm } from "../filter_search_term";
import { ApiLoadingGuard } from "../ApiLoadingGuard/ApiLoadingGuard";


export function LockGroupsPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [orgInfo, setOrgInfo] = React.useState<ApiCallState<IOrganizationInfo>>({});
  const [lockGroups, setLockGroups] = React.useState<ApiCallState<ILockGroup[]>>({});
  const [searchTerm, setSearchTerm] = React.useState('');
  const [sortAscending, setSortAscending] = React.useState(true);

  // load org info
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgInfo(token);
    })().then((result) => setOrgInfo({ result })).catch((err) => setOrgInfo({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  // load lock groups
  React.useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await apiGetOrgLockGroups(token);
    })().then((result) => setLockGroups({ result })).catch((err) => setLockGroups({ error: `${err}` }))
  }, [getAccessTokenSilently]);

  function switchSortOrder() {
    setSortAscending(!sortAscending)
  }

  return (
    <ApiLoadingGuard apiStates={[orgInfo, lockGroups]}>
      <div className="container pt-5">
        <h1>Schließgruppen</h1>
        <p>Organisation: {orgInfo.result?.name}</p>
        <p>PMS: <PmsLogo pms={orgInfo.result?.gmType}/></p>
        <br />
        <br />
        {lockGroups.result?.length ?
          <div>
            <div className="mb-3">
              <input type="text" className="form-control" id="Searchbar" placeholder="Nach Objekt suchen..." onChange={event => { setSearchTerm(event.target.value) }} />
              <br />
              <table className="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "5%" }}>Status</th>
                    <th scope="col">Name <i role='button' className="bi bi-arrow-down-up" onClick={() => switchSortOrder()} /></th>
                    {/*<th scope="col" style={{width: "5%"}}>#Schlüssel</th> */}{/* TODO: Uncomment - just for fair demo */}
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filterSearchTerm<ILockGroup>('displayName', searchTerm)(lockGroups.result || [])
                      .sort((a, b) => a.displayName.localeCompare(b.displayName) * (sortAscending ? 1 : -1))
                      .map((b, idx) => <LockGroupsRow key={idx} lockGroup={b} />)
                  }
                </tbody>
              </table>
              <div className="pt-1 pb-5">
                <i className="bi bi-key-fill text-success"></i>&nbsp;Alle Schlüsselübergaben erfolgreich &nbsp;
                <i className="bi bi-key-fill text-warning"></i>&nbsp;Schlüsselübergaben ausstehend &nbsp;
                <i className="bi bi-key-fill text-danger"></i>&nbsp;Fehler&nbsp;
              </div>
            </div>
          </div>
          :
          <p className="pt-5 pb-5">Keine Schließgruppen vorhanden.</p>}
      </div>
    </ApiLoadingGuard>
  );
}
