
import { MouseEventHandler, PropsWithChildren } from 'react';
import styles from './Modal.module.scss';

export interface ModalProps {
  onCancel?: MouseEventHandler;
  onConfirm?: MouseEventHandler;
}

export function Modal(props: PropsWithChildren<ModalProps>) {
  return (
    <div className={styles.modal}>
      {props.children}
      <br />
      <button className='btn btn-danger m-1' onClick={(ev) => props.onCancel ? props.onCancel(ev) : null}>Abbrechen</button>
      <button className='btn btn-secondary m-1' onClick={(ev) => props.onConfirm ? props.onConfirm(ev) : null}>Bestätigen</button>
    </div>
  );
}
